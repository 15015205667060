import React from 'react'
import { createRoot } from "react-dom/client";
import './assets/styles/common-styles.scss'
import './tools/GlobalConfig'
import App from './App.js'
import * as serviceWorker from './serviceWorker'

const root = createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
