import React from 'react'
import styles from '../assets/styles/home.module.scss'
import {Layout} from 'antd'
import {useNavigate} from "react-router"

const {Header, Footer, Content} = Layout

export default function () {
    let navigate = useNavigate()

    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName)
            if (anchorElement) {
                anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'})
            }
        }
    }

    return (
        <div className={styles.container}>
            <Layout className={styles.layout}>
                <Header id="Header" className={styles.header}>
                    <div onClick={() => scrollToAnchor('product_section')}>
                        <img alt='' className={styles.logo} src={require('../assets/images/logo-with-name.png')}/>
                    </div>
                    <div className={styles.menu_link_box}>
                        <a onClick={() => scrollToAnchor("Download")}
                           className={styles.menu_link_item}>Home</a>
                        <a onClick={() => scrollToAnchor("Download")}
                           className={styles.menu_link_item}>Download</a>
                        <a onClick={() => scrollToAnchor("Features")}
                           className={styles.menu_link_item}>Features</a>
                        <a onClick={() => navigate('/support')}
                           className={styles.menu_link_item}>Support</a>
                    </div>
                    {/*<div className={styles.buy_button}>Buy Now</div>*/}
                </Header>
                <Content className={styles.content}>
                    <div id="Download" className={styles.download_section}>
                        <div className={styles.introduce}>
                            <div className={styles.title}>Promise of Privacy</div>
                            <div className={styles.details}>
                                Ganan derivates from Go to Canaan, being a promised land by Yahweh.
                                <br/>
                                We at Ganan protect your privacy with the same spirituality.
                            </div>
                            <div style={{height: 20}}></div>
                            <img src={require('../assets/images/btn-app-store.png')} className={styles.btn_download}
                                 onClick={() => {
                                     window.location.href = 'https://apps.apple.com/us/app/ganan-net/id1625533060'
                                 }}/>
                            <div style={{height: 0}}></div>
                            <img src={require('../assets/images/btn-google-play.png')} className={styles.btn_download}
                                 onClick={() => {
                                     window.location.href = 'https://play.google.com/store/apps/details?id=com.ganannet'
                                 }}/>
                        </div>
                        <img src={require('../assets/images/top-section.png')} className={styles.image}/>
                    </div>

                    <div id="Features" className={styles.features_section}>
                        <div className={styles.introduce}>
                            {/*<div className={styles.advertising}>Best iOS VPN</div>*/}
                            <div className={styles.title}>
                                From darkness into the marvelous light.
                            </div>
                            <div className={styles.details}>
                                Connect to the truth, your only source of belief.
                            </div>
                        </div>
                        <img src={require('../assets/images/features-demo.png')} className={styles.image}/>
                    </div>


                    <div id="Speed" className={styles.speed_section}>
                        <div className={styles.introduce}>
                            {/*<div className={styles.advertising}>Speed and stability</div>*/}
                            <div className={styles.title}>Private and Encrypted Tunnel</div>
                            <div className={styles.details}>
                                Your information on the wire go through your dedicated and encrypted tunnel.
                            </div>
                        </div>
                        <img src={require('../assets/images/speed-stability.png')} className={styles.image}/>
                    </div>


                    <div id="Privacy" className={styles.privacy_section}>
                        <img src={require('../assets/images/speed-stability.png')} className={styles.image}/>
                        <div className={styles.introduce}>
                            {/*<div className={styles.advertising}>Privacy and Security</div>*/}
                            <div className={styles.title}>Disciples that pursue your privacy</div>
                            <div className={styles.details}>
                                Our call for protecting your privacy is preeminent over everything.
                            </div>
                        </div>
                    </div>


                    <div id="Support" className={styles.support_section}>
                        <div className={styles.introduce}>
                            <div className={styles.title}>Support</div>
                            {/*<div className={styles.details}>
                                    24 x 7 fast technical support.
                                    <br/>
                                    Trusted by customers worldwide.
                                </div>*/}
                            <div className={styles.email}><a onClick={() => navigate('/support')}>Contact Us</a></div>
                        </div>
                        <img src={require('../assets/images/support.png')} className={styles.image}/>
                    </div>


                    <footer id="page_foot" className={styles.footer}>
                        <div className={styles.footer_links}>
                            <div className={styles.link_column}>
                                <div className={styles.link_header}>Content</div>
                                <ul className={styles.link_list}>
                                    <li><a onClick={() => scrollToAnchor("Download")}>Home</a></li>
                                    <li><a onClick={() => scrollToAnchor("Download")}>Download</a></li>
                                    <li><a onClick={() => navigate('/support')}>Support</a></li>
                                    <li><a href="./privacy-policy.html">Privacy Policy</a></li>
                                    <li><a href="./terms-of-service.html">Terms Of Service</a></li>
                                </ul>
                            </div>
                            {/*<div className={styles.link_column}>
                                    <div className={styles.link_header}>Legal</div>
                                    <ul className={styles.link_list}>
                                        <li><a href="/terms-of-service.html">Terms of service</a></li>
                                        <li><a href="/privacy-policy.html">Privacy Policy</a></li>
                                    </ul>
                                </div>*/}
                        </div>
                        <div className={styles.copyright}></div>
                    </footer>
                </Content>
            </Layout>
        </div>
    )
}


