import React from 'react'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Home from './views/Home'
import Support from './views/Support'
import CloseAccount from './views/CloseAccount'

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/register" element={<Home/>}/>
                <Route path="/support" element={<Support/>}/>
                <Route path="/close-account" element={<CloseAccount/>}/>
            </Routes>
        </Router>
    )
}
