import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import styles from '../assets/styles/support.module.scss'
import {Button, Form, Input, Layout, Space, Modal, Result, message} from 'antd'
import WebServerRestClient from "../tools/WebServerRestClient"

const {Header, Content} = Layout

export default function () {
    let navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [sendButtonDisabled, setSendButtonDisabled] = useState(false)
    const [sendButtonText, setSendButtonText] = useState('Send')
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [email, setEmail] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [isAccountClosed, setAccountClosed] = useState(false)

    const sendEmailCode = () => {
        if (email === '' || email.trim().length === 0) {
            message.warning('Please input the email account')
            return
        }

        setSending(true)
        new WebServerRestClient().POST('/open/send-close-account-email', {
            email: email,
            code: 2004,
        }).then(response => {
            setSending(false)
            if (response.code === 200) {
                countDown()
                setSending(false)
                message.success('Send successfully')
            } else {
                message.warning(response['msg'])
            }
        }).catch(err => {
            setSending(false)
            message.error("Error: " + err.message)
        })
    }

    function countDown() {
        let totalTime = 45;
        setSendButtonDisabled(true)
        let sendEmailTimer = setInterval(() => {
            totalTime--;
            setSendButtonText(`${totalTime} S`)
            if (totalTime === 0) {
                totalTime = 45;
                clearInterval(sendEmailTimer);
                setSendButtonDisabled(false);
                setSendButtonText(`Resend`)
            }
        }, 1000)
    }

    const onValidateFrom = () => {
        if (email === '' || email.trim().length === 0) {
            message.warning('Please input the email')
            return
        }
        if (verificationCode === '' || verificationCode.trim().length === 0) {
            message.warning('Please input the verification code')
            return
        }
        setShowConfirmModal(true);
    }

    const onSubmitFrom = () => {
        setLoading(true);
        new WebServerRestClient().POST('/open/close-account', {
            email: email,
            validationCode: verificationCode
        }).then(response => {
            setLoading(false)
            if (response.code === 200) {
                message.success('Message submitted');
                setShowConfirmModal(false);
                setAccountClosed(true);
            } else {
                message.warning(response.msg)
            }
        }).catch(err => {
            setLoading(false)
            message.error(err.message)
        })
    }

    return (
        <div className={styles.container} style={{height: '100vh'}}>
            <Layout className={styles.layout}>
                <Header id="Header" className={styles.header}>
                    <div onClick={() => navigate('/')} style={{cursor: 'pointer'}}>
                        <img alt='' className={styles.logo} src={require('../assets/images/logo-with-name.png')}/>
                    </div>
                    <div className={styles.menu_link_box}>
                    </div>
                </Header>
                <Content className={styles.content}>
                    {isAccountClosed ?
                        <Result
                            status="success"
                            title="Success"
                            subTitle={`Your account[${email}] has been deleted!`}
                        />
                        :
                        <Form labelCol={{span: 4}}
                              wrapperCol={{span: 14}}
                              layout="horizontal"
                              className={styles.form}>
                            <Form.Item label="Email">
                                <Input onChange={(e) => setEmail(e.target.value)}/>
                            </Form.Item>
                            <Form.Item label="Verification Code">
                                <Space.Compact style={{width: '100%'}}>
                                    <Input onChange={(e) => setVerificationCode(e.target.value)}/>
                                    <Button type="primary" loading={sending} className={'primary_color'}
                                            disabled={sendButtonDisabled}
                                            onClick={() => sendEmailCode()}>
                                        {sendButtonText}
                                    </Button>
                                </Space.Compact>
                            </Form.Item>
                            <Form.Item label="">
                                <Button className={styles.commit_button} loading={loading}
                                        onClick={() => onValidateFrom()}>Submit</Button>
                            </Form.Item>
                        </Form>
                    }
                    <Modal
                        title="Delete Account"
                        open={showConfirmModal}
                        okText="Confirm"
                        cancelText="Cancel"
                        onOk={() => onSubmitFrom()}
                        onCancel={() =>setShowConfirmModal(false)}>
                            <p>Are you sure you want to delete your account? This operation cannot be restored.</p>
                    </Modal>
                </Content>
            </Layout>
        </div>
    )
}


