import StorageUtils from "./StorageUtils";

export default class WebServerRestClient {

    constructor () {
        this.baseUrl = "https://api.ganan.io";
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: StorageUtils.hasToken()
        };
    }

    _fullRoute (url) {
        return `${this.baseUrl}${url}`;
    }

    _fetch (route, method, body, isQuery = false) {
        if (!route) throw new Error('Route is undefined');
        let fullRoute = this._fullRoute(route);
        if (isQuery && body) {
            const qs = require('qs');
            const query = qs.stringify(body);
            fullRoute = `${fullRoute}?${query}`;
            body = undefined;
        }
        let opts = {
            method,
            headers: this.headers
        };
        if (body) {
            Object.assign(opts, { body: JSON.stringify(body) });
        }
        const fetchPromise = () => fetch(fullRoute, opts);  //instead of fetch
        return fetchPromise()
            .then(response => response.json());
    }

    GET (route, query) { return this._fetch(route, 'GET', query, true); }
    POST (route, body) { return this._fetch(route, 'POST', body); }
    PUT (route, body) { return this._fetch(route, 'PUT', body); }
    DELETE (route, query) { return this._fetch(route, 'DELETE', query, true); }
}
