/**
 * global constants
 */
window.CurrentUser = {
    userId: '',
    email: '',
    ethAddress: '',
    token: ''
};

window.constants = {
    LANGUAGE_EN: "EN",
    LANGUAGE_CN: "CN",
    LANGUAGE_KEY: "language",
};

window.downloads = {

};

window.products = {

};
