import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import styles from '../assets/styles/support.module.scss'
import {Button, Form, Input, Layout, message} from 'antd'
import WebServerRestClient from "../tools/WebServerRestClient"

const {TextArea} = Input

const {Header, Content} = Layout

export default function () {
    let navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')

    const onSubmitMessage = () => {
        if (email === '' || email.trim().length === 0) {
            message.warning('Please input the email')
            return false
        }
        if (subject === '' || subject.trim().length === 0) {
            message.warning('Please input the subject')
            return false
        }
        if (content === '' || content.trim().length === 0) {
            message.warning('Please input the message')
            return false
        }

        setLoading(true);
        new WebServerRestClient().POST('/open/send-support-email', {
            code: 2003,
            email: email,
            subject: subject,
            message: content
        }).then(response => {
            setLoading(false)
            if (response.code === 200) {
                message.success('Message submitted');
                navigate('/');
            } else {
                message.warning(response.msg)
            }
        }).catch(err => {
            setLoading(false)
            message.error(err.message)
        })
    }

    return (
        <div className={styles.container} style={{height: '100vh'}}>
            <Layout className={styles.layout}>
                <Header id="Header" className={styles.header}>
                    <div onClick={() => navigate('/')} style={{cursor: 'pointer'}}>
                        <img alt='' className={styles.logo} src={require('../assets/images/logo-with-name.png')}/>
                    </div>
                    <div className={styles.menu_link_box}>
                    </div>
                </Header>
                <Content className={styles.content}>
                    <Form labelCol={{span: 4}}
                          wrapperCol={{span: 14}}
                          layout="horizontal"
                          className={styles.form}>
                        <Form.Item label="Email">
                            <Input onChange={(e) => setEmail(e.target.value)}/>
                        </Form.Item>
                        <Form.Item label="Subject">
                            <Input onChange={(e) => setSubject(e.target.value)}/>
                        </Form.Item>
                        <Form.Item label="Message">
                            <TextArea rows={5} onChange={(e) => setContent(e.target.value)}/>
                        </Form.Item>
                        <Form.Item label="">
                            <Button className={styles.commit_button} loading={loading} onClick={() => onSubmitMessage()}>Submit</Button>
                        </Form.Item>
                    </Form>
                </Content>
            </Layout>
        </div>
    )
}


